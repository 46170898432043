import React from 'react'
import { Navbar,Dropdown,Container,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../assets/style.css';
import facebook from '../assets/images/facebook.png';
import chat from '../assets/images/chat.png';
import { useLocation } from "react-router-dom";

const NavTop = () => {
    
    const location = useLocation();
    
    const { pathname } = location;

    // console.log('pathname => '+pathname);
    
        return(
            <div>
                <div className="row">
                    <div className="col-md-12">
                    <Navbar className="shadow-sm" style={{backgroundColor:"#FFF",color:"#A4C639"}} fixed="top" collapseOnSelect expand="lg">
                        <Container>
                        <Navbar.Brand  style={{fontSize:25,fontWeight:"bold"}} href="/">
                            ZURIDESK
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link className={pathname === "/" ? "active" : ""} href="/"> 
                                    <img src={facebook} className="img-fluid nav-img" alt="" />
                                     <span style={{marginTop:10}}>Facebook</span>
                                </Nav.Link>
                                <Nav.Link className={pathname === "/facebook/posts" ? "active" : ""} href="/facebook/posts"> 
                                    <img src={facebook} className="img-fluid nav-img" alt="" />
                                     <span style={{marginTop:10}}>Facebook Posts</span>
                                </Nav.Link>
                                <Nav.Link className={pathname === "/webchat" ? "active" : ""} href="/webchat">
                                    <img src={chat} className="img-fluid nav-img" alt="" />
                                     <span style={{marginTop:10}}>Webchat</span>
                                </Nav.Link>
                            </Nav>
                            <Nav>
                                <Nav.Link className="bounce-out-on-hover">
                                <NavDropdown
                                id="nav-dropdown-dark-example"
                                title="Agent Name"
                                menuVariant="dark"
                                >
                                    <NavDropdown.Item href="">Logout</NavDropdown.Item>
                                </NavDropdown>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        </Container>
                        </Navbar>
                    </div>
                </div>
            </div>
        )  
    }

export default NavTop;