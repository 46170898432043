import React from 'react';
import { Container,Accordion,Button,Row,Col,Card} from 'react-bootstrap';
import NavTop from '../components/NavTop';
import Footer from '../components/Footer';
import swal from 'sweetalert';
import Loader from "react-js-loader";
import Moment from 'react-moment';
import UserAvatar from 'react-user-avatar';
import { isWebUri } from 'valid-url';
// import 'moment-timezone';


class Posts extends React.Component{

  constructor(props){
  super(props);
  this.state = {user: null,token:null,data: null,posts:[], count:0,isLoading:true,loaded:false,message:null,chats:[],user_id:null,sendingMessage:false};
}


  async componentDidMount(){

    document.title = 'Zuridesk | Posts';

    var user = localStorage.getItem('user');
    var token = localStorage.getItem('token');

    var token = localStorage.getItem('token');

      if(!token){
        window.location.href = "/";
      }

    this.setState({ 
      user: user,
      token:token
    })

    const response = await fetch('https://zuridesk.com/api/facebook/post/all');
    const data = await response.json();
    this.setState({ 
      posts: data,
      isLoading:false,
      chatLoaded:false,
      loaded:true
    })
            
  }


  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }
  
  
  

  viewChat = async(id) => {
    
    this.setState({ 
      chatLoading:true,
      user_id:id,
    })
   
    const response = await fetch('https://zuridesk.com/api/facebook/message/get/'+id);
    const data = await response.json();
    this.setState({ 
      chats: data,
      chatLoading:false,
      chatLoaded:true,
    })

    this.scrollToBottom();

  }

  

  textChanged  = (evt) => {

    this.setState({ 
      message: evt.target.value
    })

    var textarea = document.getElementById("textarea");
    var heightLimit = 500; /* Maximum height: 200px */
  
    textarea.style.height = ""; /* Reset the height*/
    textarea.style.height = Math.min(textarea.scrollHeight, heightLimit) + "px";


  }


  sendMessage = async() => {

    var message = this.state.message;
    var user_id = this.state.user_id;

    this.setState({
      sendingMessage:true,
    })

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "message": message})
    };
    const response = await fetch("https://zuridesk.com/api/facebook/message/reply/"+user_id, requestOptions);
    const data = await response.json();
    console.log("RESPONSE => "+JSON.stringify(data));
    this.setState({
      sendingMessage:false,
    })
    if(data.success){
      this.viewChat(this.state.user_id)
      this.setState({
        message:"",
      })
    }

  }
  

  render(){
      return(

        <React.Fragment>

        <NavTop></NavTop>

        <div className="main-section">

        {/* Content */}

        {/* Content Starts Here */}

        <Container>
          

         {this.state.isLoading && (<div style={{flex: 1, alignSelf:'center',justifyContent: 'center'}}>
            <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"spinner-cub"} color={'#FFFFFF'} size={100} />
        </div>) }

        
          {!this.state.isLoading && (

            <Col className="justify-conten-center" md={6}>

              <h1 className="chat_h1">Posts</h1>

             
              <div  className="contact-menu">
              {this.state.posts.map((post,key) => {
                 return(
              <div key={key} className="contact">
              
              <Card className="post-card shadow-sm">
                <Card.Body>

                    <Row>
                    <Col md={2}>
                      <img className="avatar-md" alt="" src="https://zuridesk.com/assets/facebook/img/user.png"/>
                    </Col>
                    <Col md={6}>
                      <h6 style={{marginLeft:-20}} className="contact-name">Zuridesk Demo</h6>
                      <p className="one-line" style={{fontSize:12,marginLeft:-20}}> <small><Moment>{post.created_at}</Moment></small> </p>
                    </Col>
                  </Row>

                  <Card.Text>
                   {post.message}
                  </Card.Text>
                  
                </Card.Body>

                <Card.Footer>
                 <h5>Comments</h5>
                </Card.Footer>


                {post.comments.map((comment,key) => {
                 return(
                <div style={{marginLeft:30,marginTop:10}} key={key}>
                  <Row>
                    <Col md={2}>
                      <img className="avatar-comment" alt="" src="https://zuridesk.com/assets/facebook/img/user.png"/>
                    </Col>
                    <Col md={6}>
                      <h6 style={{marginLeft:-20}} className="contact-name">{comment.from_name}</h6>
                      <p style={{marginLeft:-20}}> {comment.message}</p>
                      <p className="one-line" style={{fontSize:12,marginLeft:-20}}> <small><Moment>{comment.created_at}</Moment></small> </p>
                    </Col>
                  </Row>
                    
                </div>
                  )

                })}
                
              </Card>
              </div>
              )

              })}


              </div>
            
            </Col>

          ) }
        </Container>

        {/* End of Content */}

        {/* Content Ends Here */}

        </div>

        <Footer></Footer>

        </React.Fragment>
          
      )
  }

}

export default Posts;