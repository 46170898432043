import React from 'react';
import { Container,Row,Col,Card} from 'react-bootstrap';
import swal from 'sweetalert';
import Loader from "react-js-loader";
import LoadingOverlay from 'react-loading-overlay';



class Home extends React.Component{

  constructor(props){
  super(props);
  this.state = {data: null,contacts:[],submitting:false,email:'',password:'',errorEmailShow:false,erroPasswordShow:false};
}


  async componentDidMount(){

    document.title = 'Zurisocials | Login';

     var token = localStorage.getItem('token');

      if(token){
        window.location.href = "/home";
      }

            
  }

  emailChanged  = (evt) => {

    this.setState({ 
      email: evt.target.value
    })

  }

  passwordChanged  = (evt) => {

    this.setState({ 
      password: evt.target.value
    })

  }

  login = () =>{

      var email = this.state.email;
      var password = this.state.password;

      if(!email){
        this.setState({
          errorEmailShow:true
        })
      }

      if(!password){
        this.setState({
          errorPaswordShow:true
        })
      }


      if(email && password){
        this.setState({
          submitting:true,
        })
     
      
            fetch("https://zuridesk.com/api/auth/login", {
              method: 'POST',
              headers: {
                'Content-type': 'application/json'
              },
              body: JSON.stringify({
                'email':email,
                'password':password
              })

            })
              .then((response) => response.json())
              .then((response) => {
                this.setState({
                  submitting:false,
                })

                  if(response.success){
                    localStorage.setItem('token', response.access_token);
                    localStorage.setItem('user', JSON.stringify(response.user));
                    window.location.href = "/home";
                  }else if(response.message.email){
                    swal("Required!", "The Email field is required", "info");
                  }else if(response.message.password){
                    swal("Required!", "The Password field is required", "info");
                  }else{
                    swal("Unauthorized!", response.message, "error");
                  }

              })
              

        }
  
}
  

  render(){
      return(

        <React.Fragment>

        <div className="main-section">

        {/* Content */}

        {/* Content Starts Here */}

        <Container>

        <div className="login-card col-md-6">
          <Card className="shadow">

          <LoadingOverlay
          active={this.state.submitting}
          spinner
          text='Authenticating please wait...'
          >

          <Card.Header style={{backgroundColor:"#039BE5"}} className="login-card-header shadow-lg">
              <h5 className="login-heading"><strong>Login to our site</strong></h5>
              <p className="login-p">Enter your username and password to log on</p>
            </Card.Header>
            <div className="row justify-content-center padding-md">

            <label className="login-label-first" htmlFor="email">Email</label>
            <input onChange={this.emailChanged} type="email" className="form-control login-input" placeholder="Please enter email address"></input>
            {this.state.errorEmailShow  && (
                 <p className="error">Email field is required!</p>      
            ) }
           
            <label className="login-label" htmlFor="password">Password</label>
            <input type="password" onChange={this.passwordChanged} className="form-control login-input" autoComplete="off" placeholder=""></input>
              {this.state.erroPasswordShow  && (
                  <p className="error">Password field is required!</p>  
              ) }
            <button onClick={() => {this.login()}}  className="btn btn-primary mb-4 btn-login">Login</button>
            </div>
          

        </LoadingOverlay>

          </Card>

        </div>
          
        <Col  >
         
        </Col>
         
        </Container>

        {/* End of Content */}

        {/* Content Ends Here */}

        </div>

        </React.Fragment>
          
      )
  }

}

export default Home;