import React from 'react';
import { Container,Row,Col,Card} from 'react-bootstrap';
import NavTop from '../components/NavTop';
import Footer from '../components/Footer';
import swal from 'sweetalert';
import Loader from "react-js-loader";
import Moment from 'react-moment';
import UserAvatar from 'react-user-avatar';
import { isWebUri } from 'valid-url';
// import 'moment-timezone';


class Home extends React.Component{

  constructor(props){
  super(props);
  this.state = {user: null,token:null,data: null,contacts:[], count:0,isLoading:true,loaded:false,message:null,chats:[],user_id:null,sendingMessage:false};
}


  async componentDidMount(){

    document.title = 'Zuridesk | Home';

    var user = localStorage.getItem('user');
    var token = localStorage.getItem('token');

    var token = localStorage.getItem('token');

      if(!token){
        window.location.href = "/";
      }

    this.setState({ 
      user: user,
      token:token
    })

    const response = await fetch('https://zuridesk.com/api/facebook/message/contacts');
    const data = await response.json();
    this.setState({ 
      contacts: data.messages,
      isLoading:false,
      chatLoaded:false,
      loaded:true
    })
            
  }


  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }
  
  
  

  viewChat = async(id) => {
    
    this.setState({ 
      chatLoading:true,
      user_id:id,
    })
   
    const response = await fetch('https://zuridesk.com/api/facebook/message/get/'+id);
    const data = await response.json();
    this.setState({ 
      chats: data,
      chatLoading:false,
      chatLoaded:true,
    })

    this.scrollToBottom();

  }

  

  textChanged  = (evt) => {

    this.setState({ 
      message: evt.target.value
    })

    var textarea = document.getElementById("textarea");
    var heightLimit = 500; /* Maximum height: 200px */
  
    textarea.style.height = ""; /* Reset the height*/
    textarea.style.height = Math.min(textarea.scrollHeight, heightLimit) + "px";


  }


  sendMessage = async() => {

    var message = this.state.message;
    var user_id = this.state.user_id;

    this.setState({
      sendingMessage:true,
    })

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "message": message})
    };
    const response = await fetch("https://zuridesk.com/api/facebook/message/reply/"+user_id, requestOptions);
    const data = await response.json();
    console.log("RESPONSE => "+JSON.stringify(data));
    this.setState({
      sendingMessage:false,
    })
    if(data.success){
      this.viewChat(this.state.user_id)
      this.setState({
        message:"",
      })
    }

  }
  

  render(){
      return(

        <React.Fragment>

        <NavTop></NavTop>

        <div className="main-section">

        {/* Content */}

        {/* Content Starts Here */}

        <Container>
          

         {this.state.isLoading && (<div style={{flex: 1, alignSelf:'center',justifyContent: 'center'}}>
            <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"spinner-cub"} color={'#FFFFFF'} size={100} />
        </div>) }

        
          {!this.state.isLoading && (
            
          <Row>

            <Col md={4}>

            <Card className="contacts-card shadow">

              <h1 className="chat_h1">Chats</h1>

              <form className="form-inline position-relative">
                <input type="search" className="form-control search" id="conversations" placeholder="Search for conversations..."></input>
              </form>
              <div  className="contact-menu">


            
              {this.state.contacts.map((contact,key) => {
                 return(
              <div key={key} className="contact">
              <a className="contact-link" href="#" onClick={() => {this.viewChat(contact.user_id)}}>
                <Row>
                  <Col md={2}>
                    <img className="avatar-md" alt="" src={contact.profile_pic}/>
                  </Col>
                  <Col md={6}>
                    <h6 className="contact-name">{contact.user_name}</h6>
                    <p  className="contact-p one-line">{contact.message}</p> 
                    <p className="one-line" style={{fontSize:12,marginLeft:4}}> <small><Moment>{contact.created_at}</Moment></small> </p>
                  </Col>
                </Row>
              </a>
              </div>
              )

              })}


              </div>
            </Card>
            
            </Col>


            <Col md={8}>


            {this.state.chatLoading && (<div style={{flex: 1, alignSelf:'center',justifyContent: 'center'}}>
              <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"spinner-cub"} color={'#FFFFFF'} size={100} />
            </div>) }

            {!this.state.chatLoaded && !this.state.chatLoading && (

            <div className="no-chat" id="nav-tabContent">

              <h1 className="no-chat-heading text-center">
                Please Click on a chat to preview
              </h1>

            </div>

            ) }


         
            {this.state.chatLoaded && (
            
            <div className="main chat-list">

            <div className="tab-content" id="nav-tabContent">
              
            {this.state.chats.map((chat, i, row) => {
            return(

              <div key={i}>

              {chat.type == "in" && (
              <div>

              <div className="chat-bubble-in">
                <p>
                  {chat.message}
                </p>
              </div>
              <p style={{fontSize:12,marginLeft:6,marginTop:4}}> <small><Moment>{chat.created_at}</Moment></small> </p>

              </div>
              ) }
              
              {i + 1 === row.length && (
              <div style={{ float:"left", clear: "both",marginTop:-200}}
                  ref={(el) => { this.messagesEnd = el; }}>
              </div>
              ) }
              
              <br />
              {chat.type == "out" && (
              <div>
              <div className="babble tab-pane fade active show" id="list-chat" role="tabpanel" aria-labelledby="list-chat-list">
                <div className="chat" id="chat1">

                  <div className="content" id="content">
                    <div className="container">
                      <div className="col-md-12">
                        <div className="message me">
                          <div className="text-main">
                            <div className="text-group me">
                              <div className="text me">
                                <p>{chat.message}</p>
                              </div>
                            </div>
                            <span style={{fontSize:12}}><Moment>{chat.created_at}</Moment></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              </div>
              ) }
              
              </div>

              )

            })}

              <div className="reply-view row">
               <Row>
                  <Col md={10}>
                      <textarea  onChange={this.textChanged} name="message" id="textarea" className="form-control" placeholder="Start typing for reply..." rows="1"></textarea>
                    </Col>
                    <Col md={2}>
                      {!this.state.sendingMessage  && (
                      <button onClick={() => {this.sendMessage()}} id="submitBtn" type="submit" className="btn btn-primary btn-send">Send</button>
                      ) }
                       {this.state.sendingMessage  && (
                      <button disabled className="btn btn-secondary btn-send">Sending Message</button>
                      ) }
                    </Col>
               </Row>

              </div> 

            </div>
            </div>


            ) }
            </Col>

          </Row>

          ) }
        </Container>

        {/* End of Content */}

        {/* Content Ends Here */}

        </div>

        <Footer></Footer>

        </React.Fragment>
          
      )
  }

}

export default Home;