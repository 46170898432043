import React from 'react'
import {MDBFooter} from "mdbreact";
import { Container,Col,Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
  
  class NavTop extends React.Component{

    render(){
        return(
            <div>
                <MDBFooter color="blue" className="font-small footer">
                    
                    <div className="footer-copyright text-center py-3">
                        <Container fluid>
                        &copy; {new Date().getFullYear()} Copyright Zuridesk
                        </Container>
                    </div>

                </MDBFooter>
            </div>
        )  
    }
}

export default NavTop;