import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";


import LoginPage from "./pages/login";
import HomePage from "./pages/home";
import PostsPage from "./pages/posts";

const Routes = () => {

  // useEffect(() => {
  //   document.title = "Shop Okoa"
  // }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <LoginPage />
        </Route>
        <Route exact path="/home">
          <HomePage />
        </Route>
        <Route exact path="/facebook/posts">
          <PostsPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
